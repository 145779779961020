import React, { Suspense } from "react";
import "./custom.css";
import { Container, Row, Col } from "react-bootstrap";

import Loader from "./Loader";
import Footer from "./Footer";
const Testimonials = React.lazy(() => import("./Testimonials"));

class App extends React.Component {
  render() {
    return (
      <>
        <Container className="pt-5">
          <div class="notification-top-bar">
            <div>
            Accomplish Your Goals With Personalized 'Self-talk' | Check out our new project - <a href='https://www.selftalk.ing/' target="_blank" rel="noreferrer">SELFTALK.ING</a> 📧
            </div>
          </div>
          <Row className="d-flex justify-content-center align-items-center pt-5 my-5">
            <Col xs={12} md={{ span: 12 }}>
              <Row className="d-block justify-content-center text-center align-items-center my-5">
                <Col xs={12}>
                  <span className="display-4">
                    Learn Psychology in Bite-sized Format.
                  </span>
                </Col>
                <Col xs={12} className="mt-3">
                  <h5>
                    Psych is a <b>free</b> bi-weekly newsletter which teaches
                    you psychological biases and insights.
                  </h5>
                </Col>
                {/* <Col xs={12}><span className="btn btn-primary d-flex justify-content-center d-xs-table mt-3 mx-auto">Join for Free!</span></Col> */}
              </Row>
            </Col>
            <Col className="text-center" xs={12} md={{ span: 12 }}>
              {/* <img alt='alternate' src='https://via.placeholder.com/300' className='img-fluid mx-auto d-block'/> */}
              {/* <span className='img-fluid mx-auto d-block' style={{fontSize: '200px'}} role='img'>🧠</span> */}
              <iframe
                className="signupIframe"
                title="signup"
                src="https://psych.substack.com/embed"
                frameBorder="0"
                scrolling="no"
              ></iframe>
            </Col>
          </Row>
          <Row id='thebook' className="d-flex justify-content-center align-items-center my-5">
            <Col xs={12} md={{ span: 12 }}>
              <h1>Introducing The Psych Handbook</h1>
              <div class="book-container">
              <a
                  rel="noreferrer"
                  href="https://amzn.to/3j2Z0O5"
                  target="_blank"
                >
                <img
                  alt="The Psych Handbook - Book Cover"
                  src="https://sp-production-image.s3.amazonaws.com/1400260/0ed8860d-46cb-4282-8824-3d4d2e58a2d1.jpeg"
                  class="book-cover"
                />
                </a>
              </div>
              <div className="text-center my-4">
                <a
                  rel="noreferrer"
                  href="https://read.amazon.in/kp/embed?asin=B0BSSZCLH4&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_FSJC982HXFM50YBCVEX1&tag=thebrainpsych-21"
                  target="_blank"
                  class="buy-now-button"
                >
                  Read Free preview on Amazon
                   
                </a>
                <div />
                <a
                  rel="noreferrer"
                  href="https://gum.co/psychology"
                  target="_blank"
                  class="buy-now-button"
                >
                   Buy Now from Gumroad
                </a>

              </div>
              <div className=" my-4">
                <b>The Psych Handbook</b> is a workbook of 150+ psychological
                biases & insights which has been covered in the last few years
                of the newsletter. This Workbook is an introductory compilation
                of psychological biases related to Human Nature, Behavior
                Economics, Social Psychology and an overview of Psychology in
                humans. Not only it will help to understand learn "why people do
                what they do" but it also explains certain aspects of human
                nature through the biases we tend to show in our daily
                lifestyle. <br /><br />The practical takeaways with each bias can be
                insightful to implement in your personal as well as professional
                front of life. It can act as a good introduction to psychology
                for you.
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center my-5">
            <Col xs={3} md={{ span: 3 }}>
              <h4>Learn Psychology 👩‍🏫</h4>
              <div className="rainbow-text my-2">
                <a
                  rel="noreferrer"
                  href="https://thebrainpsych.notion.site/Learn-Psychology-5e73ef85a619459181fa482674f8cf23"
                  target="_blank"
                >
                  Learn.Psych.Email ↗
                </a>
              </div>
            </Col>
            <Col xs={3} md={{ span: 3 }}>
              <h4>Train Your Brain 🍃</h4>
              <div className="rainbow-text">
                <a
                  rel="noreferrer"
                  href="https://www.flowstatetraining.co"
                  target="_blank"
                >
                  Flow State Training ↗
                </a>
              </div>
            </Col>
            <Col xs={3} md={{ span: 3 }}>
              <h4>Podcast 🎙</h4>
              <div className="rainbow-text my-2">
                <a
                  rel="noreferrer"
                  href="https://anchor.fm/thebrainpsych"
                  target="_blank"
                >
                  The Brain Psych ↗
                </a>
              </div>
            </Col>
            <Col xs={3} md={{ span: 3 }}>
              <h4>NFT ⭐️</h4>
              <div className="rainbow-text">
                <a
                  rel="noreferrer"
                  href="https://opensea.io/collection/thebrainpsych"
                  target="_blank"
                >
                  The Curious Minds ↗
                </a>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center my5">
            <Col xs={12} md={{ span: 12 }}>
              <h4>Also join the adventures on 🗻</h4>
            </Col>
            <Col className="text-center" xs={12} md={{ span: 12 }}>
              <div class="multi-button my-2">
                <button
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/thebrainpsych/",
                      "_blank"
                    )
                  }
                >
                  Instagram
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCN-6V7-R7zXedP8qWyNrIqw",
                      "_blank"
                    )
                  }
                >
                  Youtube
                </button>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center my-5">
            <Col xs={12} md={{ span: 12 }}>
              <h1>Kind Words from Community 🤗</h1>
            </Col>
            <Col xs={12} md={{ span: 12 }}>
              <Suspense fallback={<Loader />}>
                <Testimonials />
              </Suspense>
            </Col>
            <Col xs={12} md={{ span: 12 }}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default App;
